<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" item-key="_id"
			:options.sync="options" :server-items-length="totalItems"
			:loading="loading" loading-text="加载中...">
            <template v-slot:top>
				<v-row class="pl-4 mt-2">
					<v-col cols="12" md="2">
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate1" label="日期起" readonly v-bind="attrs"
									v-on="on" dense hide-details></v-text-field>
							</template>
							<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="2">
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate2" label="日期止" readonly v-bind="attrs"
									v-on="on" dense hide-details></v-text-field>
							</template>
							<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field v-model="orderid" label="订单号" clearable dense hide-details></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field v-model="title" label="主题" clearable dense hide-details></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-text-field v-model="cond" label="条件" clearable dense hide-details></v-text-field>
					</v-col>
					<v-col cols="12" md="2">
						<v-btn color="primary" @click="fetchData" class="mx-1">查询</v-btn>
					</v-col>
				</v-row>
                <v-divider/>
            </template>
            <template v-slot:item.time="{ item }">
                {{formatTime(item.time)}}
            </template>
			<template v-slot:item.content="{ item }">
				<vue-json-pretty :data="item.content" :deep="0"></vue-json-pretty>
				<!--{{getContent(item)}}-->
			</template>
        </v-data-table>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import VueJsonPretty from 'vue-json-pretty';
	import 'vue-json-pretty/lib/styles.css';
	import {formatTime, formatDate} from '../utils'

    export default {
        data() {
            return {
                authed: false,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
                headers: [
                    {text:'时间', value:'time', width:180},
                    {text:'用户名', value:'customUserId', width:120},
                    {text:'ClientIP', value:'clientIP', width:100},
                    {text:'主题', value:'title', width:180},
                    {text:'内容', value:'content', width:300, sortable: false},
                ],
                items: [],
				loading: false,
				options: {},
				totalItems: 0,
				orderid: '',
				title:'',
				cond:'',
            }
        },
		components: {
    		VueJsonPretty
		},
        mounted() {
			this.authed = this.$hasPrivilege('查看日志');
			if (!this.authed) return;
			this.formatTime = formatTime;
			this.pdate1 = formatDate(Date.now() - 30 * 86400 * 1000);
			this.pdate2 = formatDate(Date.now());
			this.fetchData();
        },
        methods: {
			async fetchData() {
				const t1 = new Date(this.pdate1 + 'T00:00:00+08:00');
				const t2 = new Date(this.pdate2 + 'T23:59:59+08:00');
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [{time:_.gte(t1).lte(t2)}];
				if (this.orderid) {
					filter.push(_.or([{"content.id": this.orderid}, {"content.orderid": this.orderid}]));
				}
				if (this.title) {
					filter.push({title:this.title});
				}
				if (this.cond) {
					const str = `({content:{${this.cond}}})`;
					filter.push(eval(str));
				}
				this.loading = true;
				try {
                    const countRes = await db.collection('log').where(_.and(filter)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('log').where(_.and(filter)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			/*
			getContent(item) {
				if (!item.content) return "";
				if (Object.prototype.toString.call(item.content) === '[object Object]') {
					return JSON.stringify(item.content);
				}
				return item.content;
			}
			*/
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			}
		}
    }
</script>
